<template>
  <transition :name="isMobile ? 'slideY' : 'reverseSlideY'">
    <div
      v-if="uiStore.getSnackBanner.value"
      id="snack-banner"
      class="fixed inset-x-0 top-16 z-[60] mx-auto flex h-fit w-10/12 flex-row items-stretch rounded-2xl text-white shadow sm:bottom-12 sm:top-auto sm:w-8/12 lg:w-[898px]"
    >
      <div
        class="flex flex-col items-center justify-center rounded-l-2xl p-2 sm:p-5"
        :class="{
          'bg-success-100': uiStore.getSnackBanner.type === 'success',
          'bg-alert-100': uiStore.getSnackBanner.type === 'info',
          'bg-error-100': uiStore.getSnackBanner.type === 'error',
        }"
      >
        <UiIcon
          :name="iconMap[uiStore.getSnackBanner.type]"
          :class="{
            'fill-success-100': uiStore.getSnackBanner.type === 'success',
            'fill-alert-100': uiStore.getSnackBanner.type === 'info',
            'fill-error-100': uiStore.getSnackBanner.type === 'error',
          }"
        ></UiIcon>
      </div>
      <div
        class="text-caption-3 sm:text-body flex flex-1 items-center rounded-r-2xl bg-black-90 px-2 py-3 text-left sm:rounded-none sm:px-8 sm:py-6"
      >
        {{ uiStore.getSnackBanner.text }}
      </div>
      <div
        v-if="uiStore.getSnackBanner.action"
        class="flex flex-row items-center justify-center gap-4 rounded-r-2xl bg-black-90 p-6"
      >
        <UiButtonBase id="close" type="secondary" class="border-white text-white" @click="executeSecondaryAction">{{
          uiStore.getSnackBanner.secondaryActionButtonText || 'Close'
        }}</UiButtonBase>
        <UiButtonBase id="action_button" @click="executeAction">{{
          uiStore.getSnackBanner.actionButtonText
        }}</UiButtonBase>
      </div>
      <div v-else class="hidden w-1/12 flex-col items-center justify-center rounded-r-2xl bg-black-90 p-6 sm:flex">
        <UiIcon name="big-close" class="cursor-pointer" @click="uiStore.resetSnackBanner()"></UiIcon>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'

const iconMap = {
  success: 'check-filled',
  info: 'info-circle-filled',
  error: 'alert-triangle-filled',
}
const uiStore = useUiStore()

const { smaller } = useBreakpoints(useGetBreakpoints())

const isMobile = smaller('xs')

watch(
  () => uiStore.getSnackBanner.value,
  (value: boolean) => {
    if (value) {
      setTimeout(
        () => {
          uiStore.resetSnackBanner()
        },
        uiStore.getSnackBanner.action ? 8000 : 4000
      )
    }
  }
)

const executeSecondaryAction = () => {
  if (!uiStore.getSnackBanner.secondaryAction) return uiStore.resetSnackBanner()
  uiStore.getSnackBanner.secondaryAction()
  uiStore.resetSnackBanner()
}

const executeAction = () => {
  if (!uiStore.getSnackBanner.action) return
  uiStore.getSnackBanner.action()
  uiStore.resetSnackBanner()
}
</script>

<style scoped></style>
